*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;;
}
a {
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 5rem 8rem;
}

.section__margin {
  margin: 4rem 6rem;
}
.avofi__preloader {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  background-color: white;
}
.avofi__loading {
  margin: auto;
}
/* ------------------------- RESPONSIVE ------------------------- */
@media screen and (max-width: 700px) {
  .section__padding {
      padding: 4 rem 6 rem;
  }
  .section__margin {
      margin: 4 rem;
  }
}
@media screen and (max-width: 550px) {
  .section__padding {
      padding: 4 rem 2 rem;
  }
  .section__margin {
      margin: 4 rem;
  }
}