@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,100;1,200;1,400;1,700&display=swap');

:root {
  font-family: 'Poppins', sans-serif;

  --gradient-button: linear-gradient(to right, #14D2E8 0%, #6156F9 100%);
  --testimonios: hsla(244, 92%, 72%, 0.15);
  --redes: linear-gradient(135deg,#FFDFE4 0%,#FCC8CC 70%);

  --color-g1: #14D2E8;
  --color-g2: #6156F9;
  --color-text: #42495B;
  --color-text-alternative: #3b3b3b;
  --color-text-light: #737373;
  --color-text-highlight: #7E76F9;
  --color-hero: #B2D4E7;

}

